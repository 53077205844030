import { cn } from '@cardo/lib';

interface LayoutPaddingXProps {
  children: React.ReactNode;
  className?: string;
}

export default function LayoutPaddingX({
  children,
  className,
}: LayoutPaddingXProps) {
  return <div className={cn('px-4 sm:px-14', className)}>{children}</div>;
}
